/**
 * Si occupa di creare elementi editabili all'interno di un elemento jQuery
 *
 * @param options Object
 */
var editable = function (options) {

    var editable = this;

    this.element = options.element;
    this.type = options.type;
    this.url = options.url;
    this.autocomplete = options.autocomplete;
    this.id = options.id;
    this.index = options.index;
    this.value = options.value;
    this.bind = options.bind || 'change';
    this.mask = options.mask;
    this.step = options.step;
    this.nullable = options.nullable;
    this.tags = options.tags;
    this.nullValue = options.nullValue;
    this.block = options.block || false;
    this.spinner = options.spinner || false;
    this.$spinner = undefined;
    this.done = options.done || function () {};
    this.fail = options.fail || function () {};
    this.params = options.params || function () {};
    this.$tr = options.tr;
    this.tags = options.tags;
    this.date_format = options.date_format || app.date_format
    this.datetime_format = options.datetime_format || app.date_format + " H:i"
    this.time_format = options.time_format || "H:i"

    var $spinner = undefined;

    this.$element = undefined;

    this.loader = function () {
        // Spinner sull'intera riga!
        var width = editable.$tr.width();
        var height = editable.$tr.height();
        $spinner = $('<div><i class="ace-icon fa fa-spinner fa-spin blue" style="font-size: 20px; line-height: ' + height + 'px"></i></div>');

        var top = editable.$tr.position().top;
        var left = editable.$tr.position().left;

        $spinner.css({
            position: 'absolute',
            'z-index': 99999,
            'background-color': 'rgba(170, 170, 170, 0.7)',
            top: top,
            left: left,
            width: width,
            height: height,
            'text-align': 'center',
            'vertical-align': 'middle'
        });

        editable.element.append($spinner);
        editable.$spinner = $spinner;
    };

    this.make = function () {
        switch (editable.type) {
            case 'select':
                this.makeSelect();
                break;
            case 'bool':
                this.makeBool();
                break;
            case 'date':
                this.makeDate();
                break;
            case 'datetime':
                this.makeDateTime();
                break;
            case 'time':
                this.makeTime();
                break;
            case 'textarea':
                this.makeTextArea();
                break;
            case 'simpletextarea':
                this.makeSimpleTextArea();
                break;
            default: // text
                this.makeText();
                break;
        }
    };

    this.fillDataToSend = function (value) {
        var data_to_send = editable.$tr.data('data_to_send');
        if (typeof data_to_send === 'undefined') {
            data_to_send = {};
        }

        data_to_send[editable.index] = value;
        editable.$tr.data('data_to_send', data_to_send);

        editable.done();
    };

    this.makeDate = function () {
        editable.makeText();

        editable.$element.datetimepicker({
            dayOfWeekStart: 1,
            format: editable.date_format,
            closeOnDateSelect: true,
            timepicker: false,
            scrollMonth:false,
            scrollInput:false
        });
    };

    this.makeDateTime = function () {
        editable.makeText();

        editable.$element.datetimepicker({
            dayOfWeekStart: 1,
            step: 30,
            format: editable.datetime_format,
            closeOnTimeSelect: true,
            scrollMonth:false,
            scrollInput:false
        });
    };

    this.makeTime = function () {
        editable.makeText();

        editable.$element.datetimepicker({
            format: editable.time_format,
            datepicker: false,
            step: editable.step,
            closeOnTimeSelect: true,
            scrollMonth: false,
            scrollInput: false,
        });
    };

    this.makeText = function () {
        var $text = $('<input class="data-dt-focus" style="width: 98%; background: #fff;" type="text" value="' + editable.value + '">');

        $text.bind(editable.bind, function () {
            var value = $(this).val();

            if (editable.$tr.data('add_client')) {
                editable.fillDataToSend(value);
            } else {
                app.block(editable.block);
                if (editable.spinner)
                    editable.loader();
                var params = {_index: editable.index, _value: value, _id: editable.id};
                var extra = editable.params;
                $.post(editable.url, $.extend({}, params, extra))
                    .done(function (data) {
                        editable.done(data, $text, $spinner);
                    })
                    .fail(function (data) {
                        editable.fail(data, $text, $spinner);
                    });
            }
        });

        $text.bind('keydown', function (e) {
            if (e.keyCode == 13) {
                e.preventDefault();
                e.stopPropagation();

                // TODO: da fixare se datatable è all'interno di una form e si preme invio
                if (editable.value != $text.val())
                    $text.trigger(editable.bind);
            }
        });

        $text.bind('keyup', function () {
            $text.css('border-color', '#F59942');
        });

        // input mask
        if (editable.mask) {
            $text.inputmask(editable.mask.mask, (editable.mask.options || undefined));
        }

        editable.element.html($text);

        editable.$element = $text;
    };

    this.makeSimpleTextArea = function () {
        var $text = $('<textarea class="data-dt-focus" style="width: 98%; background: #fff; min-height: 100px;">' + editable.value + '</textarea>');

        $text.bind(editable.bind, function () {
            var value = $(this).val();

            if (editable.$tr.data('add_client')) {
                editable.fillDataToSend(value);
            } else {
                app.block(editable.block);
                if (editable.spinner)
                    editable.loader();
                var params = {_index: editable.index, _value: value, _id: editable.id};
                var extra = editable.params;
                $.post(editable.url, $.extend({}, params, extra))
                    .done(function (data) {
                        editable.done(data, $text, $spinner);
                    })
                    .fail(function (data) {
                        editable.fail(data, $text, $spinner);
                    });
            }
        });

        // $text.bind('keydown', function (e) {
        //     if (e.keyCode == 13) {
        //         e.preventDefault();
        //         e.stopPropagation();
        //
        //         // TODO: da fixare se datatable è all'interno di una form e si preme invio
        //         if (editable.value != $text.val())
        //             $text.trigger(editable.bind);
        //     }
        // });

        $text.bind('keyup', function () {
            $text.css('border-color', '#F59942');
        });

        // input mask
        if (editable.mask) {
            $text.inputmask(editable.mask.mask, (editable.mask.options || undefined));
        }

        editable.element.html($text);

        editable.$element = $text;
    };

    this.makeTextArea = function () {
        var $textarea = $('<a href="#" class="editable editable-pre-wrapped editable-click">' + editable.value + '</a>');

        $textarea.editable({
            type: 'textarea',
            onblur: "ignore",
            emptytext: '&nbsp;&nbsp;&nbsp;',
            success: function (data, value) {
                var params = {_index: editable.index, _value: value, _id: editable.id};
                var extra = editable.params;

                if (editable.$tr.data('add_client')) {
                    editable.fillDataToSend(value);
                } else {
                    app.block(editable.block);
                    if (editable.spinner)
                        editable.loader();

                    $.post(editable.url, $.extend({}, params, extra))
                        .done(function (data) {
                            editable.done(data, $textarea, $spinner);
                        })
                        .fail(function (data) {
                            editable.fail(data, $textarea, $spinner);
                        });
                }
            }
        });

        editable.element.html($textarea);

        editable.$element = $textarea;
    };

    this.makeSelect = function () {
        var $select = $('<select class="select2" data-old-value="' + ((editable.value !== undefined) ? editable.value : "") + '" data-placeholder=" "><option value=""></option></select>');

        if (typeof editable.value != 'undefined') {
            var $option = $('<option value="' + editable.value + '" selected>' + editable.value + '</option>')
            $select.html($option);
        }

        var send = function (value) {
            if (editable.$tr.data('add_client')) {
                editable.fillDataToSend(value);
            } else {
                app.block(editable.block);
                if (editable.spinner)
                    editable.loader();
                var params = {_index: editable.index, _value: value, _id: editable.id};
                var extra = editable.params;
                $.post(editable.url, $.extend({}, params, extra))
                    .done(function (data) {
                        editable.done(data, $select, $spinner);
                    })
                    .fail(function (data) {
                        editable.fail(data, $select, $spinner);
                    });
            }
        };

        $select.bind(editable.bind, function () {
            var value = $(this).val();

            send(value);
        });

        $select.bind("select2:unselecting", function () {
            $(this).data('state', 'unselected');

            if (editable.nullable)
                send(editable.nullValue);
        });

        editable.element.html($select);

        $select.select2({
            ajax: {
                url: editable.autocomplete,
                dataType: 'json',
                delay: 100,
                type: 'POST',
                data: function (params) {
                    return {
                        id: editable.id,
                        search: params.term, // search term
                        page: params.page,
                        _data: editable.$tr.data('data_to_send')
                    };
                },
                processResults: function (data, params) {
                    // parse the results into the format expected by Select2
                    // since we are using custom formatting functions we do not need to
                    // alter the remote JSON data, except to indicate that infinite
                    // scrolling can be used
                    params.page = params.page || 1;

                    return {
                        results: data.items
                    };
                },
                cache: true
            },
            minimumResultsForSearch: 8,
            containerCssClass: "data-dt-focus",
            tags: typeof editable.tags != 'undefined' ? editable.tags : false,
            escapeMarkup: function (markup) {
                return markup;
            }, // let our custom formatter work
            minimumInputLength: 0,
            allowClear: ((typeof editable.nullable != 'undefined') ? editable.nullable : true),
            cache: true,
            templateResult: function (data) {
                return data.text;
            },
            templateSelection: function (data) {
                if (typeof data.label == 'undefined')
                    data.label = data.text;
                return data.label;
            }
        }).on("select2:open", function(e) {
            if ($(this).data('state') === 'unselected') {
                $(this).removeData('state');

                var self = $(this);
                setTimeout(function() {
                    self.select2('close');
                }, 1);
            }
        });

        editable.$element = $select;
    };

    this.makeBool = function () {
        var value = editable.value;
        if (value === "false") value = false;
        if (value === "true") value = true;
        if (("" + value).indexOf("fa-check") >= 0) value = true;
        if (("" + value).indexOf("fa-times") >= 0) value = false;
        if (value === "0") value = false;
        if (value === "1") value = true;

        var icon = value ? "check" : "times";
        var color = value ? "green" : "red";
        var $bool = $('<button type="button" data-value="' + value + '" class="data-dt-focus btn btn-xs btn-link"><i class="fa fa-' + icon + " " + color + '"></i></button>');

        if (editable.$tr.data('add_client')) {
            editable.fillDataToSend(value ? 1 : 0);
        }

        $bool.bind(editable.bind, function () {
            var value = $(this).attr("data-value");
            if (value === "true") value = false;
            if (value === "false") value = true;
            editable.$element.attr("data-value", value);

            // per validazione Laravel
            value = (value) ? 1 : 0;
            if (value) {
                editable.$element.html('<i class="fa fa-check green"></i>');
            } else {
                editable.$element.html('<i class="fa fa-times red"></i>');
            }

            if (editable.$tr.data('add_client')) {
                editable.fillDataToSend(value);
            } else {
                app.block(editable.block);
                if (editable.spinner)
                    editable.loader();

                var params = {_index: editable.index, _value: value, _id: editable.id};
                var extra = editable.params;

                $.post(editable.url, $.extend({}, params, extra))
                    .done(function (data) {
                        editable.done(data, $bool, $spinner);
                    })
                    .fail(function (data) {
                        editable.fail(data, $bool, $spinner);
                    });
            }
        });

        editable.element.html($bool);
        editable.$element = $bool;
    };

    if (this.$tr.data('add_client')) {
        this.$tr.data('editable', editable);
    }

};